<template>
    <marketing-layout :has-landing-footer="true">
        <div class="container-fluid px-0">
            <section-block class="pb-sm-1 pt-3 pt-md-8">
                <div
                    id="origination-form"
                    class="container"
                >
                    <div class="row">
                        <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0 text-start">
                            <section-header variant="text-primary">
                                Low Variable APRs<sup><a href="#footnote_1">1</a></sup>
                            </section-header>
                            <h1 class="mb-3">
                                <img
                                    class="img-fluid"
                                    :src="require(`@/assets/images/experiments/${heraclesParameter.EXPERIMENT_NAME}/apr.svg`)"
                                    :alt="$t(`experiments.${heraclesParameter.EXPERIMENT_NAME}.aprAltText`)"
                                >
                            </h1>
                            <h3 class="text-responsive mb-1">
                                Home equity rates.<br>On a credit card.
                            </h3>
                            <h5 class="text-responsive fw-light mb-3 me-lg-3">
                                Get rates like a home equity line of credit but with the simplicity of a credit card. Plus 1.5% unlimited cashback.<sup><a href="#footnote_5">5</a></sup> Decision in
                                minutes. 100% online.
                            </h5>

                            <origination-invite-form
                                id-prefix="main-origination-form"
                                class="pe-lg-8"
                                :code-required="codeRequired"
                                :show-forgot-code-option="showForgotCodeOption"
                                :mail-offer-link="{ name: 'invite', path: '/invite' }"
                                ref="originationInviteFormHero"
                                show-safe-credit-score
                                cta-text="Check your offer"
                            />
                        </div>
                        <div class="col-md-6 position-absolute aven-hand p-0">
                            <picture>
                                <source
                                    srcset="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-md.webp"
                                    type="image/webp"
                                >
                                <source
                                    srcset="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-md.jpg"
                                    type="image/jpeg"
                                >
                                <img
                                    src="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-md.jpg"
                                    class="w-100 img-fluid d-none d-lg-block d-xl-none"
                                    alt="Aven Card"
                                >
                            </picture>
                            <picture>
                                <source
                                    srcset="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-lg.webp"
                                    type="image/webp"
                                >
                                <source
                                    srcset="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-lg.jpg"
                                    type="image/jpeg"
                                >
                                <img
                                    src="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-lg.jpg"
                                    class="w-100 img-fluid d-none d-xl-block"
                                    alt="Aven Card"
                                >
                            </picture>
                        </div>
                    </div>
                </div>
                <div class="mobile-image" />
            </section-block>
            <!-- currently all experiments except default experiment will display this section -->
            <section class="d-lg-none ps-3">
                <picture>
                    <source
                        srcset="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-sm.webp"
                        type="image/webp"
                    >
                    <source
                        srcset="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-sm.jpg"
                        type="image/jpeg"
                    >
                    <img
                        src="@/assets/images/pages/marketing/landingV2_5/aven-credit-card-hand-sm.jpg"
                        class="img-fluid w-100"
                        alt="Aven Card"
                    >
                </picture>
            </section>

            <div class="container centered mt-3">
                <hr>
            </div>

            <section-block>
                <div class="container">
                    <div class="row position-relative">
                        <div class="col">
                            <section-header
                                variant="text-primary"
                                class="text-md-center d-md-flex flex-column align-items-center"
                                underline
                            >
                                What is it
                            </section-header>
                            <h3 class="text-responsive mb-5 mb-md-1 mt-2 text-md-center">
                                Credit card in the front, home equity in the back.
                            </h3>
                            <h3 class="text-responsive text-md-center mb-0">
                                The world's first home equity backed credit card.
                            </h3>
                        </div>
                    </div>
                </div>
            </section-block>

            <div class="container">
                <hr>
            </div>

            <how-it-works-section />

            <div class="container">
                <hr>
            </div>

            <card-comparison-section />

            <div class="container">
                <hr>
            </div>

            <section-block>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                Limits
                            </section-header>
                            <h3 class="text-responsive mt-2">
                                Up to $100K
                            </h3>
                            <h5 class="text-responsive fw-light">
                                Get the benefits of a high credit limit with the flexibility to only use what you need. Starting at $5K.
                            </h5>
                        </div>
                        <div class="col-md">
                            <picture>
                                <source
                                    srcset="@/assets/images/pages/marketing/landingV2_5/line-size.webp"
                                    type="image/webp"
                                >
                                <source
                                    srcset="@/assets/images/pages/marketing/landingV2_5/line-size.png"
                                    type="image/png"
                                >
                                <img
                                    class="w-100 img-fluid mw-illustration"
                                    src="@/assets/images/pages/marketing/landingV2_5/line-size.png"
                                    alt="Limits"
                                >
                            </picture>
                        </div>
                    </div>
                </div>
            </section-block>

            <!-- can we just add container class to hr -->
            <div class="container">
                <hr>
            </div>

            <section-block>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-sm order-md-2">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                Home Equity
                            </section-header>
                            <h3 class="text-responsive mt-2">
                                Easiest way to use home equity.
                            </h3>
                            <h5 class="text-responsive fw-light">
                                Using your home as collateral, like a mortgage, we can get you home equity rates, all in 15 minutes and entirely online.
                            </h5>
                        </div>
                        <div class="col-md">
                            <picture>
                                <source
                                    srcset="@/assets/images/pages/marketing/landingV2_5/home-equity-aven.webp"
                                    type="image/webp"
                                >
                                <source
                                    srcset="@/assets/images/pages/marketing/landingV2_5/home-equity-aven.png"
                                    type="image/png"
                                >
                                <img
                                    class="w-100 img-fluid mw-illustration"
                                    src="@/assets/images/pages/marketing/landingV2_5/home-equity-aven.png"
                                    alt="Home Equity"
                                >
                            </picture>
                        </div>
                    </div>
                </div>
            </section-block>

            <!-- can we just add container class to hr -->
            <div class="container">
                <hr>
            </div>

            <section-block>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-sm">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                Balance Transfer
                            </section-header>
                            <h3 class="text-responsive mb-2 mt-2">
                                No balance transfer fee<sup><a href="#footnote_1">1</a></sup>
                            </h3>
                            <h5 class="text-responsive fw-light">
                                Transfer high-interest debt to a low APR with our $0 transfer fee and save big on your monthly payment.
                            </h5>
                        </div>
                        <div class="col-md">
                            <picture>
                                <source
                                    srcset="@/assets/images/pages/marketing/landingV2_5/balance-transfer-aven.webp"
                                    type="image/webp"
                                >
                                <source
                                    srcset="@/assets/images/pages/marketing/landingV2_5/balance-transfer-aven.png"
                                    type="image/png"
                                >
                                <img
                                    class="w-100 img-fluid mw-illustration"
                                    src="@/assets/images/pages/marketing/landingV2_5/balance-transfer-aven.png"
                                    alt="Balance Transfer"
                                >
                            </picture>
                        </div>
                    </div>
                </div>
            </section-block>

            <div class="container">
                <hr>
            </div>

            <section-block>
                <div class="container">
                    <div class="row align-items-start align-items-md-center">
                        <div class="col-sm order-md-2">
                            <section-header
                                variant="text-primary"
                                class="text-md-center d-md-flex flex-column align-items-center"
                                underline
                            >
                                Cash Back
                            </section-header>
                            <h3 class="text-responsive mb-2 mb-md-1 mt-2 text-md-center">
                                1.5% unlimited cashback on all purchases <sup><a href="#footnote_5">5</a></sup>
                            </h3>
                            <h5 class="text-responsive fw-light text-md-center mb-0">
                                Earn unlimited 1.5% cashback on every single purchase on your Aven card.
                            </h5>
                        </div>
                    </div>
                </div>
            </section-block>

            <div class="container">
                <hr>
            </div>

            <section-block>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-sm">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                Fees
                            </section-header>
                            <h3 class="text-responsive mb-2 mt-2">
                                <span v-html="$t(`experiments.${heraclesParameter.EXPERIMENT_NAME}.feeTitle`)" /><sup><a href="#footnote_1">1</a></sup>
                            </h3>
                            <h5 class="text-responsive fw-light">
                                We try to avoid as many fees as possible to keep things simple.
                            </h5>
                        </div>
                        <div class="col-md">
                            <picture>
                                <source
                                    srcset="@/assets/images/pages/marketing/landingV2_5/no-fees-aven.webp"
                                    type="image/webp"
                                >
                                <source
                                    srcset="@/assets/images/pages/marketing/landingV2_5/no-fees-aven.png"
                                    type="image/png"
                                >
                                <img
                                    class="w-100 img-fluid mw-illustration"
                                    src="@/assets/images/pages/marketing/landingV2_5/no-fees-aven.png"
                                    alt="Minimal Fees"
                                >
                            </picture>
                        </div>
                    </div>
                </div>
            </section-block>

            <div class="container">
                <hr>
            </div>

            <section-block>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-sm order-md-2">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                Visa Card
                            </section-header>
                            <h3 class="text-responsive mb-2 mt-2">
                                All the benefits of a credit card
                            </h3>
                            <h5 class="text-responsive fw-light">
                                As cheap as home equity, and as simple as a credit card. It’s both!
                            </h5>
                        </div>
                        <div class="col-md">
                            <ul class="h-100 d-flex flex-column justify-content-around list-unstyled">
                                <li class="pt-3 pt-md-0">
                                    <img
                                        src="@/assets/images/global/icon-visa.svg"
                                        alt="Visa Card"
                                        class="pe-2"
                                    >Visa Card &amp; Network
                                </li>
                                <li class="pt-2">
                                    <img
                                        src="@/assets/images/global/icon-globe.svg"
                                        alt="Visa Card"
                                        class="pe-2"
                                    >Accepted Globally
                                </li>
                                <li class="pt-2">
                                    <img
                                        src="@/assets/images/global/icon-pin.svg"
                                        alt="Visa Card"
                                        class="pe-2"
                                    >US-based Customer Service
                                </li>
                                <li class="pt-2">
                                    <img
                                        src="@/assets/images/global/icon-mobile-phone.svg"
                                        alt="Visa Card"
                                        class="pe-2"
                                    >Simple Web &amp; Mobile Apps
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section-block>

            <div class="container">
                <hr>
            </div>

            <section-block>
                <div class="container">
                    <div class="row align-items-start">
                        <div class="col-sm">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                Home Improvement
                            </section-header>
                            <h3 class="text-responsive my-2">
                                Perfect for home improvements
                            </h3>
                            <h5 class="text-responsive fw-light">
                                Broken pipe? Bathroom upgrade? Use our fast and simple access to your home equity to start that home improvement project you have wanted to do. Big or small.
                            </h5>
                        </div>
                        <div class="d-block d-md-none container my-5">
                            <hr>
                        </div>
                        <div class="col-sm">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                Tax Savings
                            </section-header>
                            <h3 class="text-responsive my-2">
                                Deduct interest on your home projects
                            </h3>
                            <h5 class="text-responsive fw-light mb-0">
                                Did you know home improvement projects may be considered tax deductible! Consult a tax advisor regarding the deductibility of interest and charges.
                            </h5>
                        </div>
                    </div>
                </div>
            </section-block>

            <div class="container">
                <hr>
            </div>

            <section-block>
                <div class="container">
                    <div class="row align-items-start">
                        <div class="col-sm">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                Cash-Out
                            </section-header>
                            <h3 class="text-responsive my-2">
                                Send Cash to Your Bank
                            </h3>
                            <h5 class="text-responsive fw-light mb-0">
                                Directly deposit cash from your Aven card to your bank account at the same low rate. Some charges may apply<sup><a href="#footnote_1">1</a></sup>
                            </h5>
                        </div>
                    </div>
                </div>
            </section-block>

            <div class="container">
                <hr>
            </div>

            <about-us-section />

            <div class="container">
                <hr>
            </div>

            <reviews-section />

            <div class="container">
                <hr>
            </div>

            <section-block>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-sm">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                FAQ
                            </section-header>
                            <h3 class="text-responsive mb-5 mt-2">
                                More Questions?
                            </h3>
                        </div>
                        <div class="col-md">
                            <div>
                                <faq
                                    :question="$t('components.faq.homeImpact.question')"
                                    :answer="$t('components.faq.homeImpact.answerHtml')"
                                />
                                <faq
                                    :question="$t('components.faq.homeEquityLoan.question')"
                                    :answer="$t('components.faq.homeEquityLoan.answerHtml')"
                                />
                                <faq
                                    :question="$t('components.faq.criteria.question')"
                                    :answer="$t('components.faq.criteria.answerHtml')"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section-block>

            <div class="container">
                <hr>
            </div>

            <section-block>
                <div class="container">
                    <div class="row align-items-start">
                        <div class="col-sm">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                Contact Us
                            </section-header>
                            <h3 class="text-responsive my-2">
                                Reach out with any questions
                            </h3>
                            <h5
                                class="mb-3 text-responsive fw-light"
                                v-html="$t('pages.marketing.landing.reengage.contactInfoHtml')"
                            />
                            <a
                                :href="smsHref"
                                class="btn btn-secondary d-block d-md-inline-block mb-2"
                            >
                                {{ $t('pages.marketing.landing.reengage.messageUs') }}
                            </a>
                        </div>
                        <div class="d-block d-md-none container my-5">
                            <hr>
                        </div>
                        <div class="col-sm">
                            <section-header
                                variant="text-primary"
                                underline
                            >
                                Join
                            </section-header>
                            <h3 class="text-responsive my-2">
                                Check offers
                            </h3>
                            <origination-invite-form
                                id-prefix="footer-origination-form"
                                class="mw-invite-form"
                                :code-required="codeRequired"
                                :show-forgot-code-option="showForgotCodeOption"
                                :mail-offer-link="{ name: 'invite', path: '/invite' }"
                                ref="originationInviteFormFooter"
                                show-safe-credit-score
                                cta-text="Check your offer"
                            />
                        </div>
                    </div>
                </div>
            </section-block>
        </div>
    </marketing-layout>
</template>

<script>
    import Marketing from '@/layouts/Marketing'
    import { i18n } from '@/utils/i18n'
    import SectionBlock from '@/components/SectionBlock'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { Flows } from '@/flow/flowController'
    import FaqExperimental from '@/components/FaqExperimental'
    import SectionHeader from '@/components/SectionHeader'
    import OriginationInviteForm from '@/components/OriginationInviteForm'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import CardComparisonSection from '@/components/landing/CardComparisonSection'
    import HowItWorksSection from '@/components/landing/HowItWorksSection'
    import AboutUsSection from '@/components/landing/AboutUsSection'
    import { logger } from '@/utils/logger'
    import ReviewsSection from '@/components/landing/ReviewsSection'
    import { reloadPageWithCleanState } from '@/utils/routerUtils'

    const SITE_VERSION = '2.5'
    export default {
        name: 'PostJuly2021DefaultLanding',
        metaInfo: {
            title: 'Aven HELOC Card: A Credit Card backed by Home Equity',
            meta: [
                { name: 'description', content: String(i18n.t('pages.marketing.landing.description')) },
                { vmid: 'robots', name: 'robots', content: 'noindex' },
            ],
        },
        props: {
            codeRequired: {
                type: Boolean,
                required: true,
            },
            showForgotCodeOption: {
                type: Boolean,
                required: true,
            },
        },
        components: {
            'about-us-section': AboutUsSection,
            'how-it-works-section': HowItWorksSection,
            'card-comparison-section': CardComparisonSection,
            'reviews-section': ReviewsSection,
            'origination-invite-form': OriginationInviteForm,
            'section-header': SectionHeader,
            'section-block': SectionBlock,
            'marketing-layout': Marketing,
            faq: FaqExperimental,
        },
        mixins: [experimentsMixin],
        created: function () {
            appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.origination)
        },
        mounted: function () {
            if (appSessionStorage.getItem(sessionStorageKey.jwtTokens)) {
                logger.info(`User went to landing page with jwt tokens! Clearing their stale state`)
                return reloadPageWithCleanState()
            }

            this.$logEvent('view_landing_default', { query: window.location.search, version: SITE_VERSION })
        },
        computed: {
            smsHref() {
                return `sms:${contact_info.sms}`
            },
        },
    }
</script>

<style lang="scss">
    @import '../../../../styles/pages/marketing/landingV2_5';
</style>
